import React from "react"
import Sidebar from "../organisms/sidebar"
import Topbar from "../organisms/topbar"
import { Button, Space } from "antd"
import MenuButton from "../fundamentals/button/menu-button"
import NewsList from "../news-list"

const Layout: React.FC = ({ children }) => {
  return (
    <div className="flex w-full min-h-screen inter-base-regular bg-[#fff] text-grey-90">
      <div className="flex flex-col flex-1 items-center">
        {/* Header */}
        <Topbar />

        {/* text logo */}
        <div className="min-w-full min-h-[72px] flex items-center justify-end">
          <div className="text-black-5 mr-[10px] text-[18px] font-sans font-medium  ">
            山下　浩二
          </div>
          <div className="pr-[50px] text-grey-4 text-[14px] font-medium font-sans">
            様
          </div>
        </div>
        {/* Menu Btn */}
        <div className="flex min-w-full min-h-[230px] justify-center items-end">
          <MenuButton />
        </div>
        {/* News List */}
        <div className=" min-w-[1200px] max-w-[1200px] min-h-[661px] bg-white my-[116px]">
          <div className="text-center text-[24px] text-black-5 font-sans font-medium leading-[24px] mt-[49px] box-border">
            新着ニュース
          </div>
          <NewsList></NewsList>
        </div>
      </div>
    </div>
  )
}

export default Layout
